import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function QueOfrecemos() {
    return (
        <>
            <div className="section section-download" data-background-color="black">
                <Container className="text-center">
                    <Row className="justify-content-md-center">
                        <Col lg="8" md="12">
                            <h2 className="title">Que ofrecemos...</h2>
                            <h5 className="modal-title">
                                Contamos con un exclusivo sistema de control de accesos para barrios cerrados de desarrollo propio.
                                Se adapta a requerimientos particulares de cada barrio, no es un enlatado. Compatible con cualquier barrera, molinete y cámaras IP.
                                Servicio de puesta en marcha, mantenimiento preventivo y correctivo de todos los componentes de un sistema de accesos,
                                CCTV, redes y todo lo relacionado con la seguridad electrónica y la informática.
                                Trabajamos con una amplia gama de productos: barreras vehiculares, molinetes, cámaras, tarjetas,
                                lectores, paneles de control, alarmas, antenas e insumos informaticos,  entre otras cosas.
                            </h5>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default QueOfrecemos;
