import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function CompleteExamples() {
  return (
    <>
      <div className="section">
        <Container className="text-center">
          <Row className="justify-content-md-center">
            <Col lg="8" md="12">
              <h2 className="title">Quienes somos...</h2>
              <h5 className="modal-title">
                Somos una empresa joven, que piensa en grande.
                Talali es una empresa de tecnología especializada en el desarrollo y
                fabricación de productos y soluciones para barrios cerrados y clubes.
                Fabricamos barreras vehiculares y desarrollamos
                un sistema de control de accesos.
              </h5>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default CompleteExamples;
