import React from "react";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function NucleoIcons() {
  return (
    <>
      <div className="section section-tabs">

        <Container>
          <Row className="justify-content-md-center">
            <Col className="text-center" lg="8" md="12">
              <h2 className="title">Nuestros principios</h2>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="12">
              <h3 className="title">Obsesión por el cliente</h3>
              <h5 className="modal-title">
                Pensamos siempre primero en el cliente, y trabajamos desde su enfoque.
                Ponemos todo nuestro empeño en ganarnos su confianza y mantenerla.
              </h5>
            </Col>
            <Col lg="6" md="12">
              <h3 className="title">Responsabilidad y compromiso</h3>
              <h5 className="modal-title">
                Tenemos visión de futuro y no sacrificamos los valores
                a largo plazo por resultados inmediatos.
              </h5>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="12">
              <h3 className="title">Pensamos en grande</h3>
              <h5 className="modal-title">
                Pensar en pequeño se traduce en resultados pequeños.
                Pensamos diferente y nos anticipamos a las necesidades de los clientes
                para encontrar nuevas maneras de darles un mejor servicio.
              </h5>


            </Col>
            <Col lg="6" md="12">
              <h3 className="title">Somos impulsados por la curiosidad</h3>
              <h5 className="modal-title">
                Nunca damos todo por aprendido y siempre tratamos de mejorar.
                Tenemos curiosidad por  nuevas posibilidades y las exploramos.
              </h5>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="12">
              <h3 className="title">Frugalidad</h3>
              <h5 className="modal-title">
                Logramos más con menos. Las limitaciones fomentan el ingenio,
                la innovación y la autonomía. No ganamos por incrementar el tamaño
                de los equipos, del presupuesto o del gasto fijo.
              </h5>
            </Col>
            <Col lg="6" md="12">
              <h3 className="title">Insistimos en los estándares más altos</h3>
              <h5 className="modal-title">
                Mantenemos siempre los estándares más altos, aunque puedan parecer exagerados.
                Subimos el nivel continuamente y obtenemos productos, servicios
                y procesos de alta calidad. Aseguramos que los defectos no se propaguen
                y de que los problemas se resuelvan de manera definitiva.
              </h5>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="12">
              <h3 className="title">Invención y simplificación</h3>
              <h5 className="modal-title">
                Siempre encontramos maneras de simplificar. Nos mantenemos informados
                de lo que ocurre alrededor, buscamos nuevas ideas en todas partes.
              </h5>
            </Col>
            <Col lg="6" md="12">

            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default NucleoIcons;
